import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ModTechImgFindIssuesAsk from './ModTechImgFindIssuesAsk';
import Dots from './Dots';
import './ModTechImgFindIssues.scss';

export default function ModTechImgFindIssues() {
  const { t, i18n } = useTranslation();

  return (
    <div className='mod-tech-img-find-issues d-flex justify-content-center'>
      <Image src={t('ModTechSection.tab0.Location1')} />
      <ModTechImgFindIssuesAsk />
      <Image src={t('ModTechSection.tab0.Group41')} className='group41' />
      <Image
        src={t('ModTechSection.tab0.Group42')}
        className={'group42' + (i18n.language === 'se' ? ' se' : '')}
      />
      <Image src={t('ModTechSection.tab0.Group43')} className='group43' />
      <Dots x={5} y={2} />
    </div>
  );
}
