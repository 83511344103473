import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import DashboardSection from '../components/DashboardSection';

export default function MobileApp() {
  return (
    <React.Fragment>
      <Header current={2} />
      <DashboardSection />
      <Footer />
    </React.Fragment>
  );
}
