import { Row, Col, Image } from 'react-bootstrap';
import './FaqButton.scss';

export default function FaqButton({ img, title, text, active, onClick }) {
  return (
    <div className={'faq-button' + (active ? ' active' : '')} onClick={onClick}>
      <Row>
        <Col xs={2} className='img-col'>
          <Image src={img} />
        </Col>
        <Col xs={10}>
          <h6>{title}</h6>
          <p className='footer-font'>{text}</p>
        </Col>
      </Row>
    </div>
  );
}
