import { Row, Col, Image } from 'react-bootstrap';
import CheckOne from '../assets/images/check-one.svg';

export default function Checkbox3({ title, text }) {
  return (
    <div className='checkbox3'>
      <Row>
        <Col
          xs={12}
          lg={1}
          className='text-center text-lg-start mb-2 mt-2 mt-lg-0'
        >
          <Image src={CheckOne} />
        </Col>
        <Col xs={12} lg={11} className='text-center text-lg-start'>
          <h6>{title}</h6>
          <p className='footer-font'>{text}</p>
        </Col>
      </Row>
    </div>
  );
}
