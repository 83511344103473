import Dot from '../assets/images/Ellipse.svg';
import { Image } from 'react-bootstrap';
import React from 'react';
import './Dots.scss';

export default function Dots({ x, y, className }) {
  const imgs = [];
  const imgRow = [];

  for (let i = 1; i <= x; i++) imgRow.push(<Image key={i} src={Dot} />);

  for (let i = 1; i <= y; i++) {
    imgs.push(
      <div key={i} className='imgs-row'>
        {imgRow}
      </div>
    );
  }

  return <div className={'dots ' + className ?? ''}>{imgs}</div>;
}
