import { resources } from '../i18n';
import { useTranslation } from 'react-i18next';
import './LangSwitcher.scss';
import { Dropdown } from 'react-bootstrap';
import React from 'react';

export default function LangSwitcher(props) {
  const { i18n } = useTranslation();
  return (
    <Dropdown className='lang-switcher'>
      <Dropdown.Toggle
        variant='secondary'
        className={
          'rounded-circle ms-4 d-flex justify-content-center align-items-center ' +
            props.className ?? ''
        }
      >
        {resources[i18n.resolvedLanguage].shortTitle}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {(() => {
          const items = [];
          for (let key in resources) {
            items.push(
              <Dropdown.Item
                key={key}
                onClick={() => {
                  i18n.changeLanguage(key);
                  props.onClick && props.onClick();
                }}
                active={key === i18n.resolvedLanguage}
              >
                {resources[key].title}
              </Dropdown.Item>
            );
          }
          return items;
        })()}
      </Dropdown.Menu>
    </Dropdown>
  );
}
