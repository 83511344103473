import React from 'react';
import FaqButton from './FaqButton';
import FaqInfoBlock from './FaqInfoBlock';
import './FaqButtonsBlock.scss';

export default function FaqButtonsBlock({ butArr, curBut, setCurBut }) {
  return (
    <div className='faq-button-block'>
      {butArr.map((but, i) => {
        return (
          <React.Fragment key={i}>
            <FaqButton
              img={but.img}
              title={but.title}
              text={but.text}
              active={i === curBut}
              onClick={() => setCurBut(i)}
            />
            <FaqInfoBlock
              infoArr={but.info}
              className={'d-lg-none' + (i === curBut ? ' active' : '')}
            />
          </React.Fragment>
        );
      })}
    </div>
  );
}
