import './TeamCard.scss';
import { Image } from 'react-bootstrap';
import LinkedInIcon from '../assets/images/linkedin.png';

export default function TeamCard({ avatar, name, desc, href }) {
  return (
    <div className='team-card'>
      <div className='img-box'>
        <Image src={avatar} alt='Avatar' />
      </div>
      <div className='notices-box'>
        <div className='ava-header'>
          <div className='img-box'>
            <Image src={avatar} alt='Avatar' />
          </div>
          <h5>{name}</h5>
        </div>
        <p className='footer-font'>{desc}</p>
        <div className='linkedin'>
          <div className='icon'>
            <Image src={LinkedInIcon} alt='LinkedIn' />
          </div>
          <a href={href} target='_blank' rel='noreferrer'>
            <p>LinkedIn</p>
          </a>
        </div>
      </div>
    </div>
  );
}
