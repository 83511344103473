import React from 'react';
import Header from '../components/Header';
import YellowSection from '../components/YellowSection';
import ModTechSection from '../components/ModTechSection';
import MoreThanAppSection from '../components/MoreThanAppSection';
import SubscribeSection from '../components/SubscribeSection';
import Footer from '../components/Footer';

export default function Home() {
  return (
    <React.Fragment>
      <Header current={0} />
      <YellowSection />
      <ModTechSection />
      <MoreThanAppSection />
      <SubscribeSection />
      <Footer />
    </React.Fragment>
  );
}
