import { Link } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import LeftChevron from '../assets/images/left-chevron.svg';
import './BackLink.scss';

export default function BackLink({ title, link }) {
  return (
    <Link to={link} className='back-link'>
      <Image src={LeftChevron} className='left-chevron' />
      {title}
    </Link>
  );
}
