import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './ModTechImgContractorsRecieve.scss';

export default function ModTechImgContractorsRecieve() {
  const { t } = useTranslation();

  return (
    <div className='mod-tech-img-contractors-recieve'>
      <Image src={t('ModTechSection.tab3.Group7927')} className='group7927' />
      <Image
        src={t('ModTechSection.tab3.MainDasboard3')}
        className='main-dasboard3'
      />
    </div>
  );
}
