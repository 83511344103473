import { Image } from 'react-bootstrap';
import Ellipse156 from '../assets/images/Ellipse156.png';
import Ellipse157 from '../assets/images/Ellipse157.png';
import Ellipse158 from '../assets/images/Ellipse158.png';
import './ThreeFaces.scss';

export default function ThreeFaces({ className }) {
  return (
    <div className={'three-faces ' + className ?? ''}>
      <Image src={Ellipse156} className='img1' />
      <Image src={Ellipse157} className='img2' />
      <Image src={Ellipse158} className='img3' />
    </div>
  );
}
