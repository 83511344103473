import { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import FaqButtonsBlock from './FaqButtonsBlock';
import FaqInfoBlock from './FaqInfoBlock';
import Login from '../assets/images/login.svg';
import Star1 from '../assets/images/star1.svg';
import Money2 from '../assets/images/money2.svg';
import './FaqSection.scss';

export default function FaqSection() {
  const [curBut, setCurBut] = useState(0);
  const { t } = useTranslation();

  const faqButArr = [
    {
      img: Login,
      title: t('FaqSection.faq1.title'),
      text: t('FaqSection.faq1.text'),
      info: [
        {
          title: t('FaqSection.faq1.info1.title'),
          text: t('FaqSection.faq1.info1.text'),
        },
        {
          title: t('FaqSection.faq1.info2.title'),
          text: t('FaqSection.faq1.info2.text'),
        },
        {
          title: t('FaqSection.faq1.info3.title'),
          text: t('FaqSection.faq1.info3.text'),
        },
      ],
    },
    {
      img: Money2,
      title: t('FaqSection.faq2.title'),
      text: t('FaqSection.faq2.text'),
      info: [
        {
          title: t('FaqSection.faq2.info1.title'),
          text: t('FaqSection.faq2.info1.text'),
        },
        {
          title: t('FaqSection.faq2.info2.title'),
          text: t('FaqSection.faq2.info2.text'),
        },
      ],
    },
    {
      img: Star1,
      title: t('FaqSection.faq3.title'),
      text: t('FaqSection.faq3.text'),
      info: [
        {
          title: t('FaqSection.faq3.info1.title'),
          text: t('FaqSection.faq3.info1.text'),
        },
        {
          title: t('FaqSection.faq3.info2.title'),
          text: t('FaqSection.faq3.info2.text'),
        },
        {
          title: t('FaqSection.faq3.info3.title'),
          text: t('FaqSection.faq3.info3.text'),
        },
        {
          title: t('FaqSection.faq3.info4.title'),
          text: t('FaqSection.faq3.info4.text'),
        },
      ],
    },
  ];

  return (
    <div className='faq-section' id='faq-section'>
      <Container>
        <Row>
          <Col xs={12} className='text-center text-lg-start'>
            <h4>{t('FaqSection.h4')}</h4>
          </Col>
          <Col xs={12} lg={6}>
            <FaqButtonsBlock
              butArr={faqButArr}
              curBut={curBut}
              setCurBut={setCurBut}
            />
          </Col>
          <Col xs={0} lg={6} className='info-block-col'>
            <FaqInfoBlock
              infoArr={faqButArr[curBut].info}
              className='d-none d-lg-block'
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
