import { Image } from 'react-bootstrap';
import Ellipse103 from '../assets/images/Ellipse103.png';
import Hint595 from '../assets/images/Hint595.png';
import { useTranslation } from 'react-i18next';
import './ModTechImgFindIssuesAsk.scss';

export default function ModTechImgFindIssuesAsk() {
  const { t, i18n } = useTranslation();

  return (
    <div className='mod-tech-img-find-issues-ask'>
      <Image src={Ellipse103} className='ellipse' />
      <Image src={Hint595} className='hint' />
      <Image
        src={t('ModTechSection.tab0.LargeTitle')}
        className={'large-title' + (i18n.language === 'se' ? ' se' : '')}
      />
    </div>
  );
}
