import React from 'react';
import { Image } from 'react-bootstrap';
import Rectangle2293 from '../assets/images/Rectangle2293.png';
import Checkbox2 from './Checkbox2';

export default function ModTechTabLeftSide({ header1, header2, checkboxes }) {
  return (
    <div className='mod-tech-tab-left-side'>
      <h3>
        {header1}
        <br />
        {header2}
        <Image src={Rectangle2293} className='birthmark' />
      </h3>
      {checkboxes.map((checkbox, i) => (
        <Checkbox2 key={i} content={checkbox} />
      ))}
    </div>
  );
}
