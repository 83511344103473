import './Checkbox2.scss';
import { Row, Col, Image } from 'react-bootstrap';
import Ellipse358 from '../assets/images/Ellipse358.svg';
import Vector41 from '../assets/images/Vector41.svg';

export default function Checkbox2({ content }) {
  return (
    <div className='checkbox2'>
      <Row>
        <Col xs={2} className='position-relative'>
          <Image src={Ellipse358} className='position-absolute' />
          <Image src={Vector41} className='position-absolute' />
        </Col>
        <Col xs={10} className='gilroy-semibold-17'>
          <p>{content}</p>
        </Col>
      </Row>
    </div>
  );
}
