import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import RequestSection from '../components/RequestSection';
import FaqSection from '../components/FaqSection';

export default function RequestDemo() {
  return (
    <React.Fragment>
      <Header current={5} />
      <RequestSection />
      <FaqSection />
      <Footer />
    </React.Fragment>
  );
}
