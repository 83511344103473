import LoginWindow from '../components/LoginWindow';
import SubcscribeInner from '../components/SubcscribeInner';

export default function Login() {
  return (
    <LoginWindow>
      <SubcscribeInner />
    </LoginWindow>
  );
}
