import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { HashLink } from 'react-router-hash-link';
import ModTechImgFindIssues from './ModTechImgFindIssues';
import ModTechImgReportThem from './ModTechImgReportThem';
import ModTechImgRecieveReport from './ModTechImgRecieveReport';
import ModTechImgContractorsRecieve from './ModTechImgContractorsRecieve';
import ModTechTabLeftSide from './ModTechTabLeftSide';
import './ModTechTabs.scss';
import { useTranslation } from 'react-i18next';

export default function ModTechTabs() {
  const [selectedTab, setSelectedTab] = useState(0);
  const { t } = useTranslation();

  const tabs = [
    {
      tabHeader: t('ModTechSection.tab0.tabHeader'),
      tabHeader2: t('ModTechSection.tab0.tabHeader2'),
      header1: t('ModTechSection.tab0.header1'),
      header2: t('ModTechSection.tab0.header2'),
      checkboxes: [
        t('ModTechSection.tab0.checkbox0'),
        t('ModTechSection.tab0.checkbox1'),
      ],
      imgs: <ModTechImgFindIssues />,
    },
    {
      tabHeader: t('ModTechSection.tab1.tabHeader'),
      tabHeader2: t('ModTechSection.tab1.tabHeader2'),
      header1: t('ModTechSection.tab1.header1'),
      header2: t('ModTechSection.tab1.header2'),
      checkboxes: [
        t('ModTechSection.tab1.checkbox0'),
        t('ModTechSection.tab1.checkbox1'),
      ],
      imgs: <ModTechImgReportThem />,
    },
    {
      tabHeader: t('ModTechSection.tab2.tabHeader'),
      tabHeader2: t('ModTechSection.tab2.tabHeader2'),
      header1: t('ModTechSection.tab2.header1'),
      header2: t('ModTechSection.tab2.header2'),
      checkboxes: [
        t('ModTechSection.tab2.checkbox0'),
        t('ModTechSection.tab2.checkbox1'),
      ],
      imgs: <ModTechImgRecieveReport />,
    },
    {
      tabHeader: t('ModTechSection.tab3.tabHeader'),
      tabHeader2: t('ModTechSection.tab3.tabHeader2'),
      header1: t('ModTechSection.tab3.header1'),
      header2: t('ModTechSection.tab3.header2'),
      checkboxes: [
        t('ModTechSection.tab3.checkbox0'),
        t('ModTechSection.tab3.checkbox1'),
      ],
      imgs: <ModTechImgContractorsRecieve />,
    },
  ];

  const tabHeadersGenerator = () =>
    tabs.map((tab, i) => {
      return (
        <div
          key={i}
          className={'tab-switcher' + (selectedTab === i ? ' current-tab' : '')}
        >
          <Row
            className='text-row'
            id={'text-row' + i}
            onClick={() => setSelectedTab(i)}
          >
            <Col xs={1} lg={0} className='text-end mt-1'>
              <HashLink to='' smooth elementId={'text-row' + i}>
                <h2 className='d-block d-lg-none'>{i + 1}</h2>
              </HashLink>
            </Col>
            <Col xs={11} lg={12} className='text-col'>
              <HashLink to='' smooth elementId={'text-row' + i}>
                <div className='tab-header'>
                  <h4>{tab.tabHeader}</h4>
                  <p>{tab.tabHeader2}</p>
                </div>
              </HashLink>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className={'h-100 w-100 d-block d-lg-none'}>
                <ModTechTabLeftSide
                  header1={tab.header1}
                  header2={tab.header2}
                  checkboxes={tab.checkboxes}
                />
              </div>
            </Col>
          </Row>
        </div>
      );
    });

  return (
    <Row className='mod-tech-tabs'>
      <Row className='tabs-switcher-row'>{tabHeadersGenerator()}</Row>
      <Row className='bottom-row'>
        <Col xs={12} lg={5} className='position-relative h-100 left-col'>
          <div className='h-100 w-100 d-none d-lg-block'>
            <ModTechTabLeftSide
              header1={tabs[selectedTab].header1}
              header2={tabs[selectedTab].header2}
              checkboxes={tabs[selectedTab].checkboxes}
            />
          </div>
        </Col>
        <Col xs={0} lg={7} className='h-100 right-col'>
          <div className='h-100 w-100 d-none d-lg-block'>
            {tabs[selectedTab].imgs}
          </div>
        </Col>
      </Row>
    </Row>
  );
}
