import Rectangle2698 from '../assets/images/Rectangle2698.png';
import Rectangle2699 from '../assets/images/Rectangle2699.png';
import { NavDropdown, Row, Col, Image } from 'react-bootstrap';
import './ProductsDropdown.scss';
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function ProductsDropdown(props) {
  const { t } = useTranslation();
  const navDropdownItems = [
    {
      title: t('Header.Citiapp Mobile'),
      text: t('Header.Where citizens'),
      img: Rectangle2698,
      to: '/mobileapp',
    },
    {
      title: t('Header.Citiapp Dashboard'),
      text: t('Header.Where owner'),
      img: Rectangle2699,
      to: '/dashboard',
    },
  ];

  return (
    <NavDropdown
      title={t('Header.Products')}
      id='products-dropdown'
      className={'products-dropdown ' + props.className}
    >
      {navDropdownItems.map((item, i) => {
        return (
          <React.Fragment key={i}>
            <NavDropdown.Item eventKey={i} as={Link} to={item.to}>
              <Row>
                <Col xs={3}>
                  <Image src={item.img} />
                </Col>
                <Col xs={9}>
                  {item.title}
                  <p
                    className='menu-gray-font'
                    dangerouslySetInnerHTML={{ __html: item.text }}
                  ></p>
                </Col>
              </Row>
            </NavDropdown.Item>
            <Row className='divider-row'>
              <Col xs={3}></Col>
              <Col xs={9} className='divider-col'>
                <NavDropdown.Divider
                  className={
                    i === navDropdownItems.length - 1 ? 'd-none' : 'mb-3'
                  }
                />
              </Col>
            </Row>
          </React.Fragment>
        );
      })}
    </NavDropdown>
  );
}
