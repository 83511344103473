import { Container, Navbar, Nav, Image, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ProductsDropdown from './ProductsDropdown';
import LangSwitcher from './LangSwitcher';
import Logo from '../assets/images/Logo.svg';
import './Header.scss';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HashLink } from 'react-router-hash-link';

export default function Header({ current }) {
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation();

  return (
    <Navbar bg='white' expand='lg' expanded={expanded}>
      <Container className='h-100 position-relative'>
        <Navbar.Brand>
          <Link to='/'>
            <Image src={Logo} fluid={true} />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls='navbar-nav'
          onClick={() => setExpanded(!expanded)}
        />
        <Navbar.Collapse id='navbar-nav'>
          <Nav
            className='flex-grow-1 align-self-stretch h-100'
            activeKey={current === 1 || current === 2 ? current - 1 : null}
          >
            <div className='d-flex d-lg-none justify-content-between align-items-baseline'>
              <p className='gilroy-reg-24'>
                {(() => {
                  switch (current) {
                    case 0:
                      return t('Header.Home');
                    case 1:
                      return t('Header.Citiapp Mobile');
                    case 2:
                      return t('Header.Citiapp Dashboard');
                    case 3:
                      return t('Header.About');
                    case 4:
                      return t('Header.Log in');
                    case 5:
                      return t('Header.Request Demo');
                    default:
                      return '';
                  }
                })()}
              </p>
              <LangSwitcher onClick={() => setExpanded(false)} />
            </div>
            <h1 className='d-lg-none'>{t('Header.Main menu')}</h1>
            <Nav.Link
              as={Link}
              to='/'
              active={current === 0}
              onClick={() => setExpanded(false)}
            >
              {t('Header.Home')}
            </Nav.Link>
            <ProductsDropdown
              className={
                'd-none d-lg-flex' +
                (current === 1 || current === 2 ? ' active' : '')
              }
            />
            <Nav.Link
              as={HashLink}
              to='/request'
              elementId='faq-section'
              className='d-lg-none'
              onClick={() => setExpanded(false)}
            >
              {t('Header.FAQ')}
            </Nav.Link>
            <Nav.Link
              as={Link}
              to='/mobileapp'
              className={'d-flex d-lg-none'}
              active={current === 1}
              onClick={() => setExpanded(false)}
            >
              {t('Header.Citiapp Mobile')}
            </Nav.Link>
            <Nav.Link
              as={Link}
              to='/dashboard'
              className={'d-flex d-lg-none'}
              active={current === 2}
              onClick={() => setExpanded(false)}
            >
              {t('Header.Citiapp Dashboard')}
            </Nav.Link>
            <Nav.Link
              as={Link}
              to='/about'
              active={current === 3}
              onClick={() => setExpanded(false)}
            >
              {t('Header.About')}
            </Nav.Link>
            <Nav.Link
              as={Link}
              to='/login'
              className={'ms-lg-auto d-none d-lg-flex'}
              active={current === 4}
            >
              {t('Header.Log in')}
            </Nav.Link>
          </Nav>
          <Button
            as={Link}
            to='/request'
            className='request-demo-button'
            onClick={() => setExpanded(false)}
          >
            {t('Header.Request Demo')}
          </Button>
          <Button
            as={Link}
            to='/login'
            variant='light'
            className='d-lg-none login-mobile-button'
            onClick={() => setExpanded(false)}
          >
            {t('Header.Log in')}
          </Button>
          <LangSwitcher className='d-none d-lg-flex' />
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
